module controllers {
    export module logistics {

        interface IVesselScheduleUpdateCtrlScope extends ng.IScope {
        }

        interface IVesselUpdateParams extends ng.ui.IStateParamsService {
             vshId: number;
        }

        export class vesselScheduleUpdateCtrl {

            static $inject = ["$scope",
                "$rootScope",
                "generalService",
                "$q",
                "bsLoadingOverlayService",
                "entityService",
                "$timeout",
                "$uibModal",
                "vesselService",
                "vesselScheduleService",
                "$state",
                "$stateParams",
                "$transitions",
                '$anchorScroll',
            ];

         

            ctrlName: string = "vessel schedule update";
            vshId: number;

         
            vesselSchedule: interfaces.logistics.VesselSchedule;
            breadCrumbDesc: string;
            public isLoadingDetail: boolean = false;
            isCopyReeferFromGP: boolean = false;

            apiVesselConsignments: uiGrid.IGridApi;            
            
            constructor(private $scope: IVesselScheduleUpdateCtrlScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService, private bsLoadingOverlayService,
                private entityService: interfaces.applicationcore.IEntityService,
                private $timeout: ng.ITimeoutService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private vesselService: interfaces.master.IVesselService,
                private vesselScheduleService: interfaces.logistics.IVesselScheduleService,
                private $state: ng.ui.IStateService,
                private $stateParams: IVesselUpdateParams,
                private $transitions: ng.ui.core.ITransition,
                private $anchorScroll: ng.IAnchorScrollService,
            ) {
                this.vshId = $stateParams.vshId;

                var loadPromises: ng.IPromise<any>[] = [];

                loadPromises.push(this.loadVesselSchedule(this.vshId));

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'ves.update'
                },
                    () => {
                        return $q.all(loadPromises);
                    });


            }

            loadVesselSchedule(vesselScheduleId : number){
                return this.vesselScheduleService.GetVesselSchedule(vesselScheduleId).query((data: interfaces.logistics.VesselSchedule) => {

                    this.vesselSchedule = data;

                    this.breadCrumbDesc = this.vesselSchedule.Vessel.Display + ' - ' + this.vesselSchedule.Terminal.Display;

                    this.isLoadingDetail = true;
                    this.$timeout(() => {
                        this.gvwVesselConsignments.data = this.vesselSchedule.VesselConsignmentList;
                        this.isLoadingDetail = false;
                    });


                }
                    , (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
            }

            registerVesselConsignmentsApi(gridApi: uiGrid.IGridApi) {
                this.apiVesselConsignments = gridApi;
            }

            loadTerminals(searchText: string) {
                return this.vesselScheduleService.GetTerminalsForDropDownByCountry(0,searchText).query({
                }, (resultList) => {
                }).$promise;
            }

            loadVessels(searchText: string) {
                return this.vesselService.getForDropDownList(searchText,100).query({
                }, (resultList) => {
                }).$promise;
            }            


            gvwVesselConsignments: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                showGridFooter: true,
                onRegisterApi: (gridApi) => {
                    this.registerVesselConsignmentsApi(gridApi);

                },
                columnDefs: [
                    {
                        name: "EDIT",
                        field: "ConsignmentId",
                        displayName: "",
                        enableFiltering: false,
                        width: 32,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.vesselScheduleUpdateCtrl.editConsignment(MODEL_COL_FIELD, row.entity.IsInbound)">
                                <span class="fa fa-pencil"></span>
                            </button>
                        </div>`
                    },
                    {
                        name: "Entity",
                        displayName: "Entity",
                        field: "Entity",
                        width: 150,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                    },
                    {
                        name: "WaybillNumber",
                        displayName: "Consignment Number",
                        field: "WaybillNumber",
                        width: 150,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                    },
                    {
                        name: "AlternateReferenceNumber",
                        displayName: "Alternate Reference Number",
                        field: "AlternateReferenceNumber",
                        width: 150,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                    }
                    ,
                    {
                        name: "Country",
                        displayName: "Country",
                        field: "Country",
                        width: 150,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                    }                   ,
                    {
                        name: "CustomerSupplier",
                        displayName: "Customer / Supplier",
                        field: "CustomerSupplier",
                        width: 150,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                    }
                    ,
                    {
                        name: "BillOfLading",
                        displayName: "Bill Of Lading",
                        field: "BillOfLading",
                        width: 150,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                    }        ,
                    {
                        name: "HWB",
                        displayName: "HWB",
                        field: "HWB",
                        width: 150,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                    }               ,
                    {
                        name: "Status",
                        displayName: "Status",
                        field: "Status",
                        width: 150,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                    }     

                ]

            }


            editConsignment(conId: number, isInbound: boolean) {
                if (isInbound) {
                    var url = '#!/ConsignmentImports/UpdateImport/'+conId;
                    window.open(url, '_blank');
                } else {
                    var url = '#!/ConsignmentExports/UpdateExport/'+conId;
                    window.open(url, '_blank');
                }
            }

            copyReeferFromGP() {
                if (!this.isCopyReeferFromGP) {
                    this.vesselSchedule.ReeferStackOpening = this.vesselSchedule.StackOpening;
                    this.vesselSchedule.ReeferStackClosing = this.vesselSchedule.StackClosing;
                    this.isCopyReeferFromGP = false;
                }
            }

            SaveVesselSchedule(): ng.IPromise<boolean> {
                var deferre = this.$q.defer<boolean>();


                this.vesselScheduleService.save().save(this.vesselSchedule, (data: interfaces.applicationcore.IMessageHandler) => {
                    this.generalService.displayMessageHandler(data);
   

                    this.vshId = Number(data.ID);

                    deferre.resolve(true);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferre.resolve(false);
                });

                return deferre.promise;
            };

            Save() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'ves.update'
                },
                    () => {
                        return this.SaveVesselSchedule().then((data: boolean) => {
                            if (data) {
                                this.loadVesselSchedule(this.vshId);
                                this.$anchorScroll("topAnchor");
                                this.$state.go("auth.VesselSailingSchedule.Update", { comId: this.vshId });
                            }
                        });
                    });

            }

            SaveClose() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'ves.update'
                },
                    () => {
                        return this.SaveVesselSchedule().then((data) => {
                            if (data) {
                                this.$state.go("^");
                            } else {
                                this.$anchorScroll("topAnchor");
                            }
                        });
                    });
            }

            Close() {
                this.$state.go("^");
            }            

        }

         angular.module("app").controller("vesselScheduleUpdateCtrl", controllers.logistics.vesselScheduleUpdateCtrl);    
    }
}